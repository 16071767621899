import {
  call, put, takeEvery, select, debounce, all,
} from 'redux-saga/effects';
import http from 'services/http';
import {
  setMembers,
  SEARCH_MEMBERS,
  GET_EMPLOYEE,
  GET_EMPLOYEE_DETAILS_RESUME,
  GET_EMPLOYEE_DETAILS_PROJECTS,
  GET_EMPLOYEE_DETAILS_SKILLS,
  GET_EMPLOYEE_DETAILS_CERTIFICATES,
  setEmployeeDetails,
  GET_EMPLOYEE_SKILLS,
  setSkills,
  setSkillLevels,
  POST_MAIN_SKILLS,
  closeSkillsModal,
  getEmployeeMainSkills,
  POST_EMPLOYEE_RESUME,
  PUT_EMPLOYEE_RESUME,
  SYNCHRONIZE_EMPLOYEES,
  UPDATE_EMPLOYEE_SKILLS,
  GET_VACATIONS,
  setVacations,
  CREATE_VACATION,
  EDIT_VACATION,
  setEmployeeResume,
  setEmployeeProjects,
  setEmployeeSkills,
  TRANSITION_TO_EDIT_PROJECT,
  setEmployeesTotalCount,
  setOrClearErrorSkills,
  setCertificate,
  POST_CERTIFICATE,
  PUT_CERTIFICATE,
  DELETE_CERTIFICATE,
  UPDATE_EMPLOYEE_OFFICE_BEFORE_RELOCATE,
  resetMembers,
  DELETE_EMPLOYEE,
  setEmployeeHolidays,
  GET_EMPLOYEE_HOLIDAYS,
  PUT_EMPLOYEE_BUSINESS_LOCATION,
  setEmployeeBusinessLocations,
  GET_EMPLOYEE_BUSINESS_LOCATIONS,
  setEmployeeResourceManagers,
  setEmployeeHrManagers,
  GET_EMPLOYEE_RESOURCE_MANAGERS,
  GET_EMPLOYEE_HR_MANAGERS,
  setBackgroundChecks,
  GET_BACKGROUND_CHECKS,
  POST_BACKGROUND_CHECK,
  PUT_BACKGROUND_CHECK,
  DELETE_BACKGROUND_CHECK,
  setCanEditBackgroundCheck,
  GET_CAN_EDIT_BACKGROUND_CHECKS,
  GET_EMPLOYEE_TRAINING,
  setEmployeeTrainings,
  POST_EMPLOYEE_TRAINING,
  PUT_EMPLOYEE_TRAINING,
  DELETE_EMPLOYEE_TRAINING,
  GET_AVAILABLE_TRAININGS,
  setAvailableTrainings,
  GET_AVAILABLE_TRAINING_CLIENTS,
  setAvailableTrainingClients,
  setEmployeeProjectInfo,
  GET_EMPLOYEE_PROJECT_INFO,
  PUT_EMPLOYEE_PROJECT_INFO,
  setCanEditEmployeeProjectInfo,
  GET_CAN_EDIT_EMPLOYEE_PROJECT_INFO,
  setShouldRestrictEmployeeInfo,
  GET_SHOULD_RESTRICT_EMPLOYEE_INFO,
  setHasBackFill,
  GET_HAS_BACK_FILL,
  setBackFillTypes,
  GET_ALL_BACK_FILL_TYPES,
  HIDE_PROJECT_INFO,
  setCertificationRequesters,
  SYNCHRONIZE_TRAININGS,
} from '../actions/membersActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import history from 'utils/history';
import { getProjectDetails, getCreationConfig } from '../../projects/sagas/projectsSaga';
import {
  CHANGE_EMPLOYEE_BASIC_INFO,
  getEmployeeBasicInfoFormData,
} from 'common/actions/reduxFormActions';
import { isFormDataChanged } from 'utils/isFormDataChanged';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { addNewNotification } from 'services/addNewNotification.js';
import { addNotify } from 'common/actions/notificationsActions';
import { loadOfficeBusinessLocations } from '../../offices/actions/officesActions';

export function* searchMembers(action) {
  try {
    yield put(startRequest());
    const {
      filters, take, skip, searchValue, sortSetting,
    } = action.payload;
    const orderByDesc = sortSetting && sortSetting.sortDirection === 'Up' ? false : true;
    const members = yield call(http, {
      method: 'GET',
      url: `employees?name=${(
        encodeURIComponent(searchValue)
      )}&skip=${skip}&take=${take}&SortField=${sortSetting && sortSetting.sortColumn}&orderByDesc=${orderByDesc}`,
      params: filters,
    });
    yield put(setEmployeesTotalCount(members.data.TotalCount));
    yield put(setMembers(members.data.Employees));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getEmployeeDetails(action) {
  try {
    yield put(startRequest());
    const employee = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.id}`,
    });
    yield put(setEmployeeDetails(employee.data));
    if (employee.data.Office && employee.data.Office.Id) {
      yield put(loadOfficeBusinessLocations(employee.data.Office.Id));
    }
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
    // error message
  }
}

export function* getEmployeeDetailsResume(action) {
  try {
    yield put(startRequest());
    const resume = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.id}/resumes`,
    });
    yield put(setEmployeeResume(resume.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
    // error message
  }
}
export function* getEmployeeDetailsProjects(action) {
  try {
    yield put(startRequest());
    const projects = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.id}/projects`,
    });
    yield put(setEmployeeProjects(projects.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
    // error message
  }
}
export function* getEmployeeDetailsSkills(action) {
  try {
    yield put(startRequest());
    const skills = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.id}/skills`,
    });
    yield put(setEmployeeSkills(skills.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
    // error message
  }
}
export function* getEmployeeDetailsCertificates(action) {
  try {
    yield put(startRequest());
    const certificates = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.id}/certificates`,
    });
    const certificationRequesters = yield call(http, {
      method: 'GET',
      url: '/certification-requesters',
    });
    yield put(setCertificate(certificates.data));
    yield put(setCertificationRequesters(certificationRequesters.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
    // error message
  }
}

export function* getMainSkills() {
  try {
    const [skills, skillLevels] = yield all([
      call(http, { method: 'GET', url: 'employees/skills' }),
      call(http, { method: 'GET', url: 'employees/skill-levels' }),
    ]);

    yield put(setSkills(skills.data));
    yield put(setSkillLevels(skillLevels.data));
  } catch (e) {
    // error message
  }
}

export function* postEmployeeMainSkills(action) {
  const data = action.payload.skills;
  const mainSkills = data.map(item => {
    const mainSkill = {
      employeeId: item.employeeId,
      SkillLevelId: item.SkillLevelId,
      skillId: item.skillId,
    };
    if (!item.skillId) {
      mainSkill.Skill = {
        name: item.skillName,
      };
    }
    return mainSkill;
  });

  try {
    yield call(http, {
      method: 'POST',
      url: `employees/main-skills/${mainSkills[0].employeeId}`,
      data: mainSkills,
    });

    yield call(getEmployeeDetails, {
      payload: {
        id: mainSkills[0].employeeId,
      },
    });

    yield put(closeSkillsModal());
    yield call(getEmployeeDetailsSkills, {
      payload: {
        id: mainSkills[0].employeeId,
      },
    });
    yield put(getEmployeeMainSkills());
    yield put(setOrClearErrorSkills());
  } catch (e) {
    if (e.response.status === 400) {
      yield put(setOrClearErrorSkills('Skill already exist'));
    }
  }
}

export function* createNewResume(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: 'employees/resume',
      data: action.payload,
    });

    yield call(getEmployeeDetails, { payload: { id: action.payload.EmployeeId } });
    yield call(getEmployeeDetailsResume, { payload: { id: action.payload.EmployeeId } });
  } catch (e) {
    // error message
  }
}

export function* updateEmployeeResume(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `employees/resume?id=${action.payload.resume.Id}`,
      data: action.payload.resume,
    });

    yield call(getEmployeeDetails, { payload: { id: action.payload.EmployeeId } });
    yield call(getEmployeeDetailsResume, { payload: { id: action.payload.EmployeeId } });
  } catch (e) {
    // error message
  }
}

export function* updateEmployeeSkills(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `employees/main-skills?id=${action.payload.skills.Id}`,
      data: {
        Skill: action.payload.skills.Skill,
        Level: action.payload.skills.Level,
      },
    });

    yield call(getEmployeeDetails, { payload: { id: action.payload.employeeId } });
    yield call(getEmployeeDetailsSkills, { payload: { id: action.payload.employeeId } });
  } catch (e) {
    // error message
  }
}

export function* updateEmployeeOfficeBeforeRelocate(action) {
  try {
    const { employeeId, locationId } = action.payload;
    const method = locationId ? 'PUT' : 'DELETE';
    yield call(http, {
      method,
      url: `employees/${employeeId}/office-before-relocate/${locationId}`,
    });

    yield call(getEmployeeDetails, { payload: { id: employeeId } });
  } catch (e) {
    // error message
  }
}

export function* updateEmployeeBusinessLocation(action) {
  try {
    const { employeeId, businessLocationId, year } = action.payload;
    yield call(http, {
      method: 'PUT',
      url: `employees/${employeeId}/business-locations/${businessLocationId}`,
    });

    yield call(getEmployeeDetails, { payload: { id: employeeId } });
    yield call(getEmployeeHolidays, { payload: { employeeId, year } });
  } catch (e) {
    // error message
  }
}

export function* synchronizeEmployees(action) {
  let urlString = '';
  if (!Array.isArray(action.payload.department)) {
    urlString = action.payload.department;
  } else {
    urlString = action.payload.department.join('&departments=');
  }
  try {
    yield call(http, {
      method: 'PUT',
      url: `auth/employees?departments=${urlString}`,
      params: action.payload.department[0],
    });
    yield put(resetMembers());
    yield searchMembers(action);
  } catch (e) {
    // error message
  }
}

export function* getVacations(action) {
  try {
    const vacations = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.employeeId}/vacations?yearOfVacation=${action.payload.year}`,
    });
    yield put(setVacations(vacations.data));
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* createVacation(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: `employees/${action.payload.employeeId}/vacations`,
      data: action.payload.vacation,
    });

    const vacations = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.employeeId}/vacations?yearOfVacation=${action.payload.year}`,
    });
    yield put(setVacations(vacations.data));
  } catch (e) {
    // error message
  }
}

export function* editVacation(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `employees/${action.payload.employeeId}/vacations/${action.payload.vacation.Id}`,
      data: action.payload.vacation,
    });

    const vacations = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.employeeId}/vacations?yearOfVacation=${action.payload.year}`,
    });

    yield put(setVacations(vacations.data));
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* transitionToEditProject(action) {
  try {
    yield put(startRequest());
    yield getProjectDetails(action);
    yield getCreationConfig();
    const { payload } = action;
    history.push({
      pathname: `/projects/${payload.id}`,
      state: {
        filters: {},
        search: '',
      },
    });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* editMembersInfo(action) {
  try {
    const form = yield select(getEmployeeBasicInfoFormData);
    if (!isFormDataChanged(form.data)) {
      return;
    }

    if (!form.data.syncErrors) {
      const sendEmployeeData = {
        BusinessTripId: form.data.values.BusinessTrip && form.data.values.BusinessTrip.Id,
        Cell: form.data.values.Cell ? form.data.values.Cell : null,
        DateOfBirth: form.data.values.DateOfBirth,
        DateOfDismissal: form.data.values.DateOfDismissal,
        Email: form.data.values.Email,
        Id: form.data.values.Id,
        JobTitleId: typeof form.data.values.JobTitleId === 'object' ? '' : form.data.values.JobTitleId,
        LocationId: typeof form.data.values.Location === 'object'
          ? form.data.values.Location && form.data.values.Location.Id
          : form.data.values.Location,
        Name: form.data.values.Name,
        Notes: form.data.values.Notes,
        OfficeId: form.data.values.Office.Id,
        Skype: form.data.values.Skype,
        StartDateInCompany: form.data.values.StartDateInCompany,
        TypeId: form.data.values.Status.Id,
        EffectiveDate: action.payload.effectiveDate,
        OfficeBeforeRelocateId: form.data.values.OfficeBeforeRelocate ? form.data.values.OfficeBeforeRelocate.Id : null,
        BusinessLocationId: typeof form.data.values.BusinessLocation === 'object'
          ? form.data.values.BusinessLocation && form.data.values.BusinessLocation.Id
          : form.data.values.BusinessLocation,
        ResourceManagerId: typeof form.data.values.ResourceManager === 'object'
          ? form.data.values.ResourceManager && form.data.values.ResourceManager.Id
          : form.data.values.ResourceManager,
        HrManagerId: typeof form.data.values.HrManager === 'object'
          ? form.data.values.HrManager && form.data.values.HrManager.Id
          : form.data.values.HrManager,
        LeavingNoticeDate: form.data.values.LeavingNoticeDate,
        PlannedLeavingDate: form.data.values.PlannedLeavingDate,
        BackFillTypeId: form.data.values.BackFillType && form.data.values.BackFillType.Id,
        BackFillDate: form.data.values.BackFillDate,
        BackFillLink: form.data.values.BackFillLink,
      };
      yield call(http, {
        method: 'PUT',
        url: `employees/${form.data.values.Id}`,
        data: sendEmployeeData,
      });

      yield call(getEmployeeDetails, {
        payload: { id: form.data.values.Id },
      });
      yield call(getEmployeeDetailsProjects, {
        payload: { id: form.data.values.Id },
      });
      yield call(getEmployeeHolidays, {
        payload: { employeeId: form.data.values.Id, year: action.payload.year },
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* loadCertificates(action) {
  const certificates = yield call(http, {
    method: 'GET',
    url: `employees/${action.payload.employeeId}/certificates`,
  });
  yield put(setCertificate(certificates.data));
}

export function* createCertificateForEmployee(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'POST',
      url: `employees/${action.payload.employeeId}/certificates`,
      data: action.payload.sendData,
    });
    yield call(loadCertificates, { payload: { employeeId: action.payload.employeeId } });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* updateCertificateForEmployee(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'PUT',
      url: `employees/${action.payload.employeeId}/certificates/${action.payload.certificateId}`,
      data: action.payload.sendData,
    });
    yield call(loadCertificates, { payload: { employeeId: action.payload.employeeId } });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* deleteCertificateForEmployee(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'DELETE',
      url: `employees/${action.payload.employeeId}/certificates/${action.payload.certificateId}`,
    });
    yield call(loadCertificates, { payload: { employeeId: action.payload.employeeId } });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* deleteEmployee(action) {
  try {
    yield put(startRequest());
    const { employeeId } = action.payload;
    const response = yield call(http, {
      method: 'DELETE',
      url: `employees/${employeeId}`,
    });
    const notify = addNewNotification(response, '', 'Employee successfully deleted');
    yield put(addNotify(notify));
    history.goBack();
  } catch (e) {
    if (e.response.data && e.response.data.data) {
      const message = Object.values(e.response.data.data).join(' ');
      yield put(showAlertDialog(message));
    }
  } finally {
    yield put(finishRequest());
  }
}

export function* getEmployeeHolidays(action) {
  try {
    const holidays = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.employeeId}/days-off?year=${action.payload.year}`,
    });
    yield put(setEmployeeHolidays(holidays.data));
  } finally {
    yield put(finishRequest());
  }
}

export function* getEmployeeBusinessLocations(action) {
  try {
    yield put(startRequest());
    const businessLocations = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.employeeId}/business-locations`,
    });
    yield put(setEmployeeBusinessLocations(businessLocations.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getEmployeeResourceManagers(action) {
  try {
    yield put(startRequest());
    const resourceManagers = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.employeeId}/resource_managers`,
    });
    yield put(setEmployeeResourceManagers(resourceManagers.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getEmployeeHrManagers(action) {
  try {
    yield put(startRequest());
    const hrManagers = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.employeeId}/hr_managers`,
    });
    yield put(setEmployeeHrManagers(hrManagers.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getCanEditBackgroundChecks(action) {
  try {
    yield put(startRequest());
    const canEdit = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload}/background_checks/can-edit`,
    });
    yield put(setCanEditBackgroundCheck(canEdit.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getBackgroundChecks(action) {
  try {
    yield put(startRequest());
    const backgroundChecks = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload}/background_checks`,
    });
    yield put(setBackgroundChecks(backgroundChecks.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* createBackgroundCheck(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'POST',
      url: `employees/${action.payload.employeeId}/background_checks`,
      data: action.payload.sendData,
    });
    yield call(getBackgroundChecks, { payload: action.payload.employeeId });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* updateBackgroundCheck(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'PUT',
      url: `employees/${action.payload.employeeId}/background_checks/${action.payload.backgroundCheckId}`,
      data: action.payload.sendData,
    });
    yield call(getBackgroundChecks, { payload: action.payload.employeeId });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* deleteBackgroundCheck(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'DELETE',
      url: `employees/${action.payload.employeeId}/background_checks/${action.payload.backgroundCheckId}`,
    });
    yield call(getBackgroundChecks, { payload: action.payload.employeeId });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getEmployeeTrainings(action) {
  try {
    yield put(startRequest());
    const employeeTrainings = yield call(http, {
      method: 'GET',
      url: `trainings/employee-trainings/${action.payload}`,
    });
    yield put(setEmployeeTrainings(employeeTrainings.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* createEmployeeTraining(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'POST',
      url: 'trainings/employee-trainings',
      data: action.payload.employeeTraining,
    });
    yield call(getEmployeeTrainings, { payload: action.payload.employeeTraining.EmployeeId });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* updateEmployeeTraining(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'PUT',
      url: `trainings/employee-trainings/${action.payload.employeeTrainingId}`,
      data: action.payload.employeeTraining,
    });
    yield call(getEmployeeTrainings, { payload: action.payload.employeeTraining.EmployeeId });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* deleteEmployeeTraining(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'DELETE',
      url: `trainings/employee-trainings/${action.payload.employeeTrainingId.item.Id}`,
    });
    yield call(getEmployeeTrainings, { payload: action.payload.employeeTrainingId.item.EmployeeId });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* loadAvailableTrainings() {
  try {
    yield put(startRequest());
    const roleTypes = yield call(http, {
      method: 'GET',
      url: 'trainings',
    });

    yield put(setAvailableTrainings(roleTypes.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* loadAvailableTrainingClients() {
  try {
    yield put(startRequest());
    const roleTypes = yield call(http, {
      method: 'GET',
      url: 'clients',
    });

    yield put(setAvailableTrainingClients(roleTypes.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* loadEmployeeProjectInfo(action) {
  try {
    yield put(startRequest());
    const projectInfo = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.employeeId}/project-info`,
    });

    yield put(setEmployeeProjectInfo(projectInfo.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* updateEmployeeProjectInfo(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'PUT',
      url: `employees/${action.payload.employeeId}/project-info/${action.payload.projectInfoId}`,
      data: action.payload.updatedProjectInfo,
    });
    yield call(loadEmployeeProjectInfo, { payload: { employeeId: action.payload.employeeId } });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getCanEditEmployeeProjectInfo(action) {
  try {
    yield put(startRequest());
    const canEdit = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.employeeId}/project-info/can-edit`,
    });
    yield put(setCanEditEmployeeProjectInfo(canEdit.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getShouldRestrictEmployeeInfo(action) {
  try {
    yield put(startRequest());
    const shouldRestrict = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.employeeId}/should-restrict-info`,
    });
    yield put(setShouldRestrictEmployeeInfo(shouldRestrict.data));
  } finally {
    yield put(finishRequest());
  }
}

export function* getHasBackFill(action) {
  try {
    yield put(startRequest());
    const hasBackFill = yield call(http, {
      method: 'GET',
      url: `employees/${action.payload.employeeId}/has-back-fill`,
    });
    yield put(setHasBackFill(hasBackFill.data));
  } finally {
    yield put(finishRequest());
  }
}

export function* getAllBackFillTypes() {
  try {
    yield put(startRequest());
    const allBackFillTypes = yield call(http, {
      method: 'GET',
      url: 'back-fill-types',
    });
    yield put(setBackFillTypes(allBackFillTypes.data));
  } finally {
    yield put(finishRequest());
  }
}

export function* hideProjectInfo(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'POST',
      url: `employees/${action.payload.employeeId}/project-info/${action.payload.projectInfoId}/hide`,
    });
    yield call(loadEmployeeProjectInfo, { payload: { employeeId: action.payload.employeeId } });
  } finally {
    yield put(finishRequest());
  }
}

export function* synchronizeTrainings() {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'PUT',
      url: 'trainings/sync',
    });
  } finally {
    yield put(finishRequest());
  }
}

export default function* membersRootSaga() {
  yield takeEvery(SEARCH_MEMBERS, searchMembers);
  yield takeEvery(GET_EMPLOYEE, getEmployeeDetails);
  yield takeEvery(GET_EMPLOYEE_DETAILS_RESUME, getEmployeeDetailsResume);
  yield takeEvery(GET_EMPLOYEE_DETAILS_PROJECTS, getEmployeeDetailsProjects);
  yield takeEvery(GET_EMPLOYEE_DETAILS_SKILLS, getEmployeeDetailsSkills);
  yield takeEvery(GET_EMPLOYEE_DETAILS_CERTIFICATES, getEmployeeDetailsCertificates);
  yield takeEvery(GET_EMPLOYEE_SKILLS, getMainSkills);
  yield takeEvery(POST_MAIN_SKILLS, postEmployeeMainSkills);
  yield takeEvery(POST_EMPLOYEE_RESUME, createNewResume);
  yield takeEvery(PUT_EMPLOYEE_RESUME, updateEmployeeResume);
  yield takeEvery(SYNCHRONIZE_EMPLOYEES, synchronizeEmployees);
  yield takeEvery(UPDATE_EMPLOYEE_SKILLS, updateEmployeeSkills);
  yield takeEvery(UPDATE_EMPLOYEE_OFFICE_BEFORE_RELOCATE, updateEmployeeOfficeBeforeRelocate);
  yield takeEvery(GET_VACATIONS, getVacations);
  yield takeEvery(CREATE_VACATION, createVacation);
  yield takeEvery(EDIT_VACATION, editVacation);
  yield takeEvery(TRANSITION_TO_EDIT_PROJECT, transitionToEditProject);
  yield debounce(150, CHANGE_EMPLOYEE_BASIC_INFO, editMembersInfo);
  yield takeEvery(POST_CERTIFICATE, createCertificateForEmployee);
  yield takeEvery(PUT_CERTIFICATE, updateCertificateForEmployee);
  yield takeEvery(DELETE_CERTIFICATE, deleteCertificateForEmployee);
  yield takeEvery(DELETE_EMPLOYEE, deleteEmployee);
  yield takeEvery(GET_EMPLOYEE_HOLIDAYS, getEmployeeHolidays);
  yield takeEvery(PUT_EMPLOYEE_BUSINESS_LOCATION, updateEmployeeBusinessLocation);
  yield takeEvery(GET_EMPLOYEE_BUSINESS_LOCATIONS, getEmployeeBusinessLocations);
  yield takeEvery(GET_EMPLOYEE_RESOURCE_MANAGERS, getEmployeeResourceManagers);
  yield takeEvery(GET_EMPLOYEE_HR_MANAGERS, getEmployeeHrManagers);
  yield takeEvery(GET_CAN_EDIT_BACKGROUND_CHECKS, getCanEditBackgroundChecks);
  yield takeEvery(GET_BACKGROUND_CHECKS, getBackgroundChecks);
  yield takeEvery(POST_BACKGROUND_CHECK, createBackgroundCheck);
  yield takeEvery(PUT_BACKGROUND_CHECK, updateBackgroundCheck);
  yield takeEvery(DELETE_BACKGROUND_CHECK, deleteBackgroundCheck);
  yield takeEvery(GET_EMPLOYEE_TRAINING, getEmployeeTrainings);
  yield takeEvery(POST_EMPLOYEE_TRAINING, createEmployeeTraining);
  yield takeEvery(PUT_EMPLOYEE_TRAINING, updateEmployeeTraining);
  yield takeEvery(DELETE_EMPLOYEE_TRAINING, deleteEmployeeTraining);
  yield takeEvery(GET_AVAILABLE_TRAININGS, loadAvailableTrainings);
  yield takeEvery(GET_AVAILABLE_TRAINING_CLIENTS, loadAvailableTrainingClients);
  yield takeEvery(GET_EMPLOYEE_PROJECT_INFO, loadEmployeeProjectInfo);
  yield takeEvery(PUT_EMPLOYEE_PROJECT_INFO, updateEmployeeProjectInfo);
  yield takeEvery(GET_CAN_EDIT_EMPLOYEE_PROJECT_INFO, getCanEditEmployeeProjectInfo);
  yield takeEvery(GET_SHOULD_RESTRICT_EMPLOYEE_INFO, getShouldRestrictEmployeeInfo);
  yield takeEvery(GET_HAS_BACK_FILL, getHasBackFill);
  yield takeEvery(GET_ALL_BACK_FILL_TYPES, getAllBackFillTypes);
  yield takeEvery(HIDE_PROJECT_INFO, hideProjectInfo);
  yield takeEvery(SYNCHRONIZE_TRAININGS, synchronizeTrainings);
}
